// Функция для обработки полей (credentials, settings)
function processField(field: any) {
	// Если поле пустое или null, возвращаем null
	if (field === '' || field === null || field === 'null') {
		return null;
	}

	// Если поле не пустое
	if (field) {
		try {
			// Если это объект, возвращаем как есть
			if (typeof field === 'object' && !Array.isArray(field)) {
				return field;
			}

			// Если это строка, парсим как JSON
			if (typeof field === 'string') {
				return JSON.parse(field);
			}

			// Если это массив, возвращаем как есть
			if (Array.isArray(field)) {
				return field;
			}

			// Если это число, возвращаем как есть
			if (typeof field === 'number') {
				return field;
			}
		} catch (error) {
			// Логируем ошибку парсинга
			console.error('Ошибка при парсинге поля:', error);
			return null; // Возвращаем null в случае ошибки
		}
	}

	// Если поле пустое, возвращаем как есть
	return field;
}

export default processField;
