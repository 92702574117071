import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Prompt, PromptFilters } from '@/modules/accounts/types';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';
import processField from '@/shared/utils/processField';

export const promptsApi = createApi({
	reducerPath: 'promptsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Prompts'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы промптов
		getPromptsMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.AI_PROMPT.META,
		}),

		// Получение списка промптов для аккаунта
		getPrompts: builder.query<
			{ ai_prompts: Prompt[]; total: number; page: number; limit: number },
			Partial<PromptFilters>
		>({
			query: (filters) => {
				const { account_id } = filters;

				// Проверка на наличие account_id
				if (!account_id) {
					throw new Error('account_id is required to fetch AI prompts');
				}

				const filterRules = {
					name: (filters: Partial<PromptFilters>, params: URLSearchParams) => {
						if (filters.name) params.append('name', filters.name);
					},
					search_string: (filters: Partial<PromptFilters>, params: URLSearchParams) => {
						if (filters.search_string)
							params.append('search_string', filters.search_string);
					},
					status: (filters: Partial<PromptFilters>, params: URLSearchParams) => {
						if (filters.status) params.append('status', filters.status.toString());
					},
					page: (filters: Partial<PromptFilters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<PromptFilters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<PromptFilters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				// Генерация параметров запроса
				const queryParams = buildQueryParams(filters, filterRules);

				// Формирование конечного URL
				const url = `${API_ENDPOINTS.AI_PROMPT.LIST(account_id)}${queryParams}`;
				return { url };
			},
			providesTags: (result) =>
				result?.ai_prompts
					? [
							...result.ai_prompts.map(({ id }: Prompt) => ({
								type: 'Prompts' as const,
								id,
							})),
							{ type: 'Prompts', id: 'LIST' },
						]
					: [{ type: 'Prompts', id: 'LIST' }],
		}),

		// Получение данных промпта по ID
		getPromptById: builder.query<Prompt, { account_id: string | number; id: string }>({
			query: (queryParams) => {
				const { account_id, id: aiPrompt_id } = queryParams;

				if (!account_id || !aiPrompt_id) {
					throw new Error('Invalid query parameters');
				}

				// Формируем запрос с осмысленными именами переменных
				return API_ENDPOINTS.AI_PROMPT.BY_ID(Number(account_id), Number(aiPrompt_id));
			},
			providesTags: (_result, _error, { id }) => [{ type: 'Prompts', id }],
		}),

		// Создание нового промпта
		createPrompt: builder.mutation<
			Prompt,
			{ account_id: string | number; body: Partial<Prompt> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'name',
					'text',
					'status',
					'system_prompt_text',
					'body',
					'settings',
				];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, ...serializedBody } = selectedBody;

				// Обрабатываем поля body и settings
				if (serializedBody.body) {
					serializedBody.body = processField(serializedBody.body);
				}

				if (serializedBody.settings) {
					serializedBody.settings = processField(serializedBody.settings);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.AI_PROMPT.CREATE(account_id),
					method: 'POST',
					body: serializedBody,
				};
			},
			invalidatesTags: [{ type: 'Prompts', id: 'LIST' }],
		}),

		// Обновление данных промпта
		updatePrompt: builder.mutation<
			Prompt,
			{ account_id: string | number; id: string | number; body: Partial<Prompt> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'name',
					'text',
					'status',
					'system_prompt_text',
					'body',
					'settings',
					'id',
				];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, id, ...serializedBody } = selectedBody;

				// Обрабатываем поля body и settings
				if (serializedBody.body) {
					serializedBody.body = processField(serializedBody.body);
				}

				if (serializedBody.settings) {
					serializedBody.settings = processField(serializedBody.settings);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.AI_PROMPT.UPDATE(account_id, id),
					method: 'PUT',
					body: serializedBody,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Prompts', id: id },
				{ type: 'Prompts', id: 'LIST' },
			],
		}),

		// Удаление промпта
		deletePrompt: builder.mutation<void, { account_id: string | number; id: string | number }>({
			query: ({ account_id, id }) => {
				// Формируем запрос для удаления
				return {
					url: API_ENDPOINTS.AI_PROMPT.DELETE(account_id, id),
					method: 'DELETE',
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Prompts', id: id },
				{ type: 'Prompts', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useGetPromptsMetaQuery,
	useGetPromptsQuery,
	useGetPromptByIdQuery,
	useCreatePromptMutation,
	useUpdatePromptMutation,
	useDeletePromptMutation,
} = promptsApi;
