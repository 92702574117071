import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store/store';
import useAuthInitialization from '@/modules/auth/hooks/useAuthInitialization';
import LoadingSpinner from '@/shared/components/LoadingSpinner/LoadingSpinner';
import RoutesWrapper from '@/app/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppContent: React.FC = () => {
	// Проверяю состояние загрузки при инициализации
	const { loading, loadingMessage } = useAuthInitialization();

	return (
		<>
			{/* Основной контент приложения */}
			<RoutesWrapper />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>

			{/* Спиннер отображается поверх основного контента при загрузке */}
			{loading && <LoadingSpinner message={loadingMessage} />}
		</>
	);
};

// Основной компонент приложения
const App: React.FC = () => (
	<Provider store={store}>
		<Router>
			<AppContent />
		</Router>
	</Provider>
);

export default App;
